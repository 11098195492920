<!-- Sidebar navigation -->
<div *ngIf="menuMain">
  <mdb-navbar appSHFB SideClass="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar intro-fixed-nav"
              [containerInside]="true">
    <mdb-navbar-brand mdbTooltip="TecnoPharmaGroup® é uma marca registrada">
      <a *ngIf="innerWidth > 414" class="navbar-brand" href="#">
        <img src="https://tecnopharmagroup.com.br/imgs/logo.png" height="40" width="160" alt="">
      </a>
    </mdb-navbar-brand>

    <links>
      <ul class="navbar-nav ml-auto nav-flex-icons mt-lg-0">
        <li class="nav-item">
          <a class="nav-link waves-light" [routerLink]="['/','dashboard']" mdbWavesEffect>
            <mdb-icon fas icon="home" class="amber-text"></mdb-icon>            
            <span class="clearfix d-none d-sm-inline-block"> Início</span>
          </a>
        </li>
        <li class="nav-item dropdown" mdbDropdown>
          <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" 
            mdbWavesEffect mdbTooltip="Saiba quem somos, como atuamos e o que fazemos.">
            <i class="fa fa-building amber-text"> </i> Institucional
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-warning" role="menu">
            <a (click)="scrollME(true)" class="dropdown-item waves-light white-text" 
               mdbWavesEffect href="#about" mdbPageScroll [pageScrollDuration]="750"
               mdbTooltip="Veja quem somos, nossa Missão, Visão e Valores." placement="left">
              <mdb-icon fas icon="info-circle" class="teal-text pr-1"></mdb-icon>Quem Somos
            </a>            
            <a (click)="scrollME(true)" class="dropdown-item waves-light white-text" 
              mdbWavesEffect href="#area" mdbPageScroll [pageScrollDuration]="750"
              mdbTooltip="Saiba sobre nossa Área de Atuação e como podemos ajuda-lo a potencializar a Gestão do seu Negócio." placement="left">
              <mdb-icon fas icon="question-circle" class="teal-text pr-1"></mdb-icon>Área de Atuação
            </a>
          </div>
        </li>
        <li class="nav-item dropdown" mdbDropdown>
          <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" 
            mdbWavesEffect mdbTooltip="Soluções e tecnologias para todas as Plataformas">
            <i class="fa fa-microchip amber-text"> </i> Soluções
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-warning" role="menu">
            <a class="dropdown-item nav-link waves-light" (click)="desktop()" mdbWavesEffect
              mdbTooltip="Soluções para dispositivos Desktop" placement="left">
              <mdb-icon fas icon="desktop" class="teal-text"></mdb-icon>
              <span class="clearfix d-none d-sm-inline-block"> Desktop</span>
            </a>
            <a class="dropdown-item nav-link waves-light" (click)="mobile()" mdbWavesEffect
              mdbTooltip="Soluções para dispositivos Mobile" placement="left">
              <mdb-icon fas icon="tablet-alt" class="teal-text"></mdb-icon>
              <span class="clearfix d-none d-sm-inline-block"> Mobile</span>
            </a>
            <a class="dropdown-item nav-link waves-light" mdbWavesEffect
              mdbTooltip="Soluções para Web" placement="left">
              <mdb-icon fas icon="sitemap" class="teal-text"></mdb-icon>
              <span class="clearfix d-none d-sm-inline-block"> Web</span>
            </a>
            <div class="divider dropdown-divider"></div>            
            <h6 class="dropdown-header">SOLUÇÕES EM DESTAQUE</h6>
            <a class="dropdown-item nav-link waves-light" href="#vendaspremiada" mdbWavesEffect 
              mdbPageScroll [pageScrollDuration]="750"
              mdbTooltip="Venda mais com o Vendas Premiadas para Distribuidores e Representantes OL. Premiando seus parceiros de Negócios, vc potencializa suas Vendas!" 
              placement="left">
              <mdb-icon fas icon="trophy" class="teal-text"></mdb-icon>
              <span class="clearfix d-none d-sm-inline-block"> Vendas Premiada</span>
            </a>  
            <a class="dropdown-item nav-link waves-light" href="#pharmaped" mdbWavesEffect 
              mdbPageScroll [pageScrollDuration]="750"
              mdbTooltip="B2BConnect - Solução de Pedido Eletrônico para Distribuidores e Representantes OL. Um novo conceito para ecommerce Pharma." 
              placement="left">
              <mdb-icon fab icon="opencart" class="teal-text"></mdb-icon>
              <span class="clearfix d-none d-sm-inline-block"> B2BConnect</span>
            </a>  
            <a class="dropdown-item nav-link waves-light" href="#pharmabot" mdbWavesEffect 
              mdbPageScroll [pageScrollDuration]="750"
              mdbTooltip="PharmaBot - Automação de Vendas e Serviços por Bot WhatsApp para Distribuidores e PDVs."
              placement="left">
              <mdb-icon fab icon="whatsapp" class="teal-text ml-1"></mdb-icon>
              <span class="clearfix d-none d-sm-inline-block"> PharmaBot</span>
            </a>  
          </div>
        </li>
        <li class="nav-item" mdbTooltip="Seja um parceiro de Negócios, aumente sua renda indicando nossas Soluções Pharma.">
          <a class="nav-link waves-light" mdbWavesEffect href="#pc" mdbPageScroll 
            [pageScrollDuration]="750">
            <i class="fa fa-hand-holding-usd amber-text"> </i>
            Seja um Parceiro
          </a>
        </li>  
        <li class="nav-item" 
          mdbTooltip="Queremos te ouvir. Tem uma sugestão, dúvida ou precisa de uma solução personalizada, entre em contato.">
          <a class="nav-link waves-light" mdbWavesEffect href="#contato" mdbPageScroll [pageScrollDuration]="750">
            <mdb-icon fas icon="envelope" class="amber-text"></mdb-icon>
            <span class="clearfix d-none d-sm-inline-block"> Contato</span>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto nav-flex-icons mt-lg-0">
        <li class="nav-item dropdown" mdbDropdown>
          <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" 
            mdbWavesEffect mdbTooltip="Dashboard Administrativas de nossas Soluções">
            <i class="fas fa-project-diagram amber-text"> </i> Dashboard
          </a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-warning" role="menu">
            <a (click)="frameLogin.show()" class="dropdown-item" mdbWavesEffect
              mdbTooltip="Acesso a sua Conta e Configurações" placement="left">
              <i class="fas fa-user-circle teal-text"></i> 
              Área do Cliente
            </a>
            <div class="divider dropdown-divider"></div>
            <a class="dropdown-item" href="https://adminvendapremiada.tecnopharmagroup.com.br" 
              target="_blank" mdbWavesEffect mdbTooltip="Dashboard Administrativa da Solução de Vendas Premiada"
              placement="left">
              <mdb-icon fas icon="user-cog" class="teal-text"></mdb-icon>
              Vendas Premiada
            </a>
            <div class="divider dropdown-divider"></div>
            <a class="dropdown-item" href="https://adminpharmaped.tecnopharmagroup.com.br" 
              target="_blank" mdbWavesEffect mdbTooltip="Dashboard Administrativa CRM de Vendas B2BConnect"
              placement="left">
              <mdb-icon fas icon="user-shield" class="teal-text"></mdb-icon>
              B2BConnect
            </a>
            <div class="divider dropdown-divider"></div>
            <a class="dropdown-item" href="https://pharmabot.tecnopharmagroup.com.br" 
              target="_blank" mdbWavesEffect mdbTooltip="Dashboard Administrativa da Solução de Vendas PhamaBot"
              placement="left">
              <mdb-icon fas icon="robot" class="teal-text"></mdb-icon>
              PharmaBot
            </a>
          </div>
        </li>
        <li class="nav-item" mdbTooltip="Visite o site de nossa parceira com soluções extra Pharma">
          <a class="nav-link waves-light" href="https://clubb2b.com.br" target="_blank" 
            mdbWavesEffect>
            <mdb-icon fas icon="globe" class="amber-text"></mdb-icon>            
            <span class="clearfix d-none d-sm-inline-block"> Visite a ClubB2B</span>
          </a>
        </li>
        <!-- li class="nav-item">
          <a class="nav-link" (click)="frameLogin.show()" mdbWavesEffect>
            <mdb-icon class="green-text" fas icon="user-circle"></mdb-icon>
            Acessar
          </a>
        </li -->
      </ul>      
    </links>
  </mdb-navbar>
</div>

<div *ngIf="menuAdmin">
  <mdb-side-nav #sidenav class="sn-bg-1" [fixed]="true">
    <mdb-navbar-brand>
      <li>
        <div class="logo-wrapper waves-light view overlay zoom">
          <a routerLink="/">
            <img src="https://tecnopharmagroup.com.br/imgs/logo.png" class="img-fluid flex-center">
          </a>
        </div>
      </li>
    </mdb-navbar-brand>

    <links>
      <li>
        <form class="search-form" role="search">
          <div class="form-group md-form mt-0 pt-1 waves-light" mdbWavesEffect>
            <input type="text" class="form-control" placeholder="Buscar">
          </div>
        </form>
      </li>
      <li>
        <ul class="collapsible collapsible-accordion">
          <mdb-accordion [multiple]="false" aria-multiselectable="false">

            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head routerLink="/sobre" mdbWavesEffect>
                <i class="fa fa-home amber-text"></i> HOME
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item>
              <mdb-accordion-item-head mdbWavesEffect>
                <i class="fa fa-microchip amber-text"></i> CRM
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li>
                    <a routerLink="/desktop" class="waves-effect" mdbWavesEffect> CLIENTES</a>
                  </li>
                  <li>
                    <a routerLink="/mobile" class="waves-effect" mdbWavesEffect> GRÁFICOS</a>
                  </li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item>
              <mdb-accordion-item-head mdbWavesEffect>
                <i class="fa fa-users amber-text"></i> FINANCEIRO
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li>
                   <a class="waves-effect" mdbWavesEffect routerLink="/login"> À RECEBER</a>
                  </li>
                  <li>
                    <a class="waves-effect" mdbWavesEffect routerLink="/suporte"> RECEBIDOS</a>
                  </li>
                  <li>
                    <a class="waves-effect" mdbWavesEffect routerLink="/forum"> AGUARDANDO CONFIRMAÇÃO</a>
                  </li>
                  <li>
                    <a class="waves-effect" mdbWavesEffect routerLink="/forum"> GRÁFICOS</a>
                  </li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head routerLink="/sobre" mdbWavesEffect>
                <i class="fas fa-comment amber-text"></i> PUSH NOTIFICATIONS</mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head routerLink="/sobre" mdbWavesEffect>
                <i class="fa fa-ambulance amber-text"></i> SUPORTE</mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head routerLink="/blog" mdbWavesEffect>
                <i class="fa fa-commenting amber-text"></i> FÓRUM</mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head routerLink="/ead" mdbWavesEffect>
                <i class="fa fa-envelope amber-text"></i> EAD</mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head routerLink="/blog" mdbWavesEffect>
                <i class="fa fa-commenting amber-text"></i> BLOG</mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item>
              <mdb-accordion-item-head mdbWavesEffect>
                <i class="fa fa-microchip amber-text"></i> APLICATIVOS</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li>
                    <a routerLink="/desktop" class="waves-effect" mdbWavesEffect> DESKTOP</a>
                  </li>
                  <li>
                    <a routerLink="/mobile" class="waves-effect" mdbWavesEffect> MOBILE</a>
                  </li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>
          </mdb-accordion>
        </ul>
      </li>
    </links>
    <div class="sidenav-bg mask-strong"></div>
  </mdb-side-nav>

  <mdb-navbar SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav" [containerInside]="true">

    <navlinks class="navbar-container">
      <div class="float-left">
        <a (click)="sidenav.show()" class="button-collapse hidden-nav-button-collapse">
          <i class="fa fa-bars amber-text"></i>
        </a>
      </div>
    </navlinks>

    <mdb-navbar-brand>
      <a class="navbar-brand" routerLink="/home" placement="bottom" mdbTooltip="Você está na DashBoard do Administrador">
        <img src="https://tecnopharmagroup.com.br/imgs/bem-vindo.png" height="40" width="160" alt="">
      </a>
    </mdb-navbar-brand>

    <navlinks>
      <ul class="nav navbar-nav nav-flex-icons ml-auto ie-double-nav ie-hidden-double-nav">
        <li class="nav-item" placement="bottom" mdbTooltip="Seu Repositório de Documentos">
          <a class="btn-floating btn-sm btn-slack" type="button" role="button"><i class="fas fa-file-alt"></i></a>
          <span class="counter">3</span>
        </li>
        <li class="nav-item" placement="bottom" mdbTooltip="Seu Repositório de Notificações">
          <a class="btn-floating btn-sm btn-so" type="button" role="button"><i class="fas fa-bell"></i></a>
          <span class="counter">9</span>
        </li>
        <li class="nav-item" placement="bottom" mdbTooltip="Suas Mensagens Recebidas do Chat">
          <a class="btn-floating btn-sm btn-comm" type="button" role="button"><i class="fas fa-comments"></i></a>
          <span class="counter">22</span>
        </li>
        <li class="nav-item" placement="bottom" mdbTooltip="Sua Caixa Postal de Emails">
          <a class="btn-floating btn-sm btn-git" type="button" role="button"><i class="fas fa-envelope"></i></a>
          <span class="counter">13</span>
        </li>
        <li class="nav-item" placement="bottom" mdbTooltip="Faz o Logout da Dashboard">
          <a class="btn-floating btn-sm btn-pin" type="button" role="button"><i class="fas fa-sign-out-alt" (click)="sair()"></i></a>
        </li>
        <li class="nav-item avatar dropdown" dropdown placement="bottom" mdbTooltip="Clique Aqui para Acessar o Menu do Usuário">
          <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            <div class="chip chip-md aqua-gradient white-text">
             <img src="https://tecnopharmagroup.com.br/imgs/{{userName.avatar}}" alt="{{userName.name}}">  {{ userName.name }} 
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-warning" aria-labelledby="navbarDropdownAvatar" role="menu">
            <a class="dropdown-item" href="#"><i class="fas fa-user-circle green-text"></i> Conta</a>
            <a class="dropdown-item" href="#"><i class="fas fa-cogs green-text"></i> Configurações</a>
            <a class="dropdown-item" href="#"><i class="fas fa-envelope green-text"></i> Emails</a>
            <a class="dropdown-item" href="#"><i class="fas fa-comments green-text"></i> Mensagens</a>
            <a class="dropdown-item" (click)="sair()"><i class="fas fa-sign-out-alt red-text"></i> Logout</a>
          </div>
        </li>
      </ul>
    </navlinks>
  </mdb-navbar>
</div>

<div mdbModal #frameLogin="mdbModal" class="modal fade top" id="frameLoginModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog form-dark" role="document">
    <mdb-card style="background-image: url(https://clubb2b.com.br/images/main/pedele-600x399.png); background-attachment: fixed;" 
              class="modal-content card-image mb-3 mx-2">
      <div class="text-white rgba-stylish-strong py-5 px-5 z-depth-4">
        <div class="modal-header text-center pb-4">
          <h3 class="modal-title w-100 white-text font-weight-bold" id="myModalLabel">
            <a class="green-text font-weight-bold">
              <strong>Login de Acesso</strong>
            </a>
          </h3>
          <button type="button" class="close white-text" data-dismiss="modal" aria-label="Close" (click)="frameLogin.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="md-form mb-5">
            <input type="email" id="Form-email5" class="form-control white-text" mdbValidate mdbInput [formControl]="modalFormDarkEmail">
            <label for="Form-email5">Seu Email</label>
            <mdb-error *ngIf="modalFormDarkEmail.invalid && (modalFormDarkEmail.dirty || modalFormDarkEmail.touched)">
              Entre com um Email válido
              </mdb-error>
            <mdb-success *ngIf="modalFormDarkEmail.valid && (modalFormDarkEmail.dirty || modalFormDarkEmail.touched)">
              Ok, seu Email é válido
            </mdb-success>
          </div>

          <div class="md-form pb-3">
            <input type="password" id="Form-pass5" class="form-control white-text" mdbValidate mdbInput [formControl]="modalFormDarkPassword">
            <label for="Form-pass5">Sua Senha</label>
            <mdb-error *ngIf="modalFormDarkPassword.invalid && (modalFormDarkPassword.dirty || modalFormDarkPassword.touched)">
              Um Senha é requerida para acesso
            </mdb-error>
            <mdb-success *ngIf="modalFormDarkPassword.valid && (modalFormDarkPassword.dirty || modalFormDarkPassword.touched)">
              Ok, sua Senha será validada
            </mdb-success>
          </div>
          <div class="md-form">
            <input type="text"
                    class="completer-input form-control mdb-autocomplete mb-0"
                    [ngModel]="searchText | async" (ngModelChange)="searchText.next($event)"
                    [mdbAutoCompleter]="auto" placeholder="Escolha um Operador de Vendas"/>
            <mdb-auto-completer #auto="mdbAutoCompleter" textNoResults="Não foram encontrados dados :(">
              <mdb-option *ngFor="let option of results | async" [value]="option.name">
                <div class="d-flex flex-column green-text">
                    <strong>{{ option.name }}</strong>
                    <!-- <small>Gender: {{ option.gender }}</small>
                    <small>Hair color: {{ option.hair_color }}</small> -->
                </div>
              </mdb-option>
            </mdb-auto-completer>                  
          </div>
          <div class="row d-flex align-items-center mb-4">
            <div class="text-center mb-3 col-md-12">
              <button type="button" mdbBtn color="success" block="true" rounded="true" class="z-depth-1 waves-light"
                      mdbWavesEffect>Entrar
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p class="font-small red-text d-flex justify-content-end">Perdeu a Senha?
                <a href="#" class="green-text ml-1 font-weight-bold"> Clique aqui para Recuperar</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </mdb-card>
  </div>
</div>