import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import jwt_decode  from 'jwt-decode';

import { TokenService } from './token.service';
import { environment } from './../../environments/environment';

import { Usuario } from './../interfaces/usuario';

import { TokenApi } from './../interfaces/respostas/token-api';
import { UsuarioService } from './usuario.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private autenticado: boolean = false;
  public  mostraDepo:  boolean = false;  
  public  userRole:    string  = '';
  public  nomeUsuario: {};
  public  userEmail:   {};

  menuMainEmitter        = new EventEmitter<boolean>();
  menuFooterEmitter      = new EventEmitter<boolean>();
  menuAdminEmitter       = new EventEmitter<boolean>();
  menuClienteEmitter     = new EventEmitter<boolean>();
  menuFarmaEmitter       = new EventEmitter<boolean>();
  menuIndEmitter         = new EventEmitter<boolean>();
  menuRepreEmitter       = new EventEmitter<boolean>();
  menuVPEmitter          = new EventEmitter<boolean>();
  menuSharedEmitter      = new EventEmitter<boolean>();
  menuColorEmitter       = new EventEmitter<boolean>();
  mostraBtnBottomEmitter = new EventEmitter<boolean>();  
  usuarioLogadoEmitter   = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private router: Router,
    private usuarioService: UsuarioService) {
  }

  logar(usuario: Usuario): Observable<boolean> {
    const url = `${environment.pharmaApiUrl}/auth/signin`;
    return this.http.post(url, usuario).pipe(
      map((resposta: TokenApi) => {
        this.nomeUsuario = resposta;
        this.userEmail   = resposta;
        this.userRole    = resposta.authorities[0];
        if(!this.criarSessao(resposta.accessToken)) {
          throw new Error();
        }
        return true;
      })
    );
  }

  deslogar(): Observable<TokenApi> {
    const url = `${environment.pharmaApiUrl}/auth/sair`;
    return this.http.post<TokenApi>(url, this.userEmail ).pipe(
      finalize(() => { this.resetSessao(); })
    );
  }

  criarSessao(token: string) {
    try {
      const usuario: Usuario = jwt_decode(token);
      this.usuarioService.setUsuario(usuario);
      this.tokenService.token = token;
      this.autenticado = true;
      this.usuarioLogadoEmitter.emit(this.nomeUsuario);
      if (this.userRole === 'ROLE_ADMIN') {
        this.menuMainEmitter.emit(false);
        this.menuFooterEmitter.emit(false);
        this.menuAdminEmitter.emit(true);
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  resetSessao() {
    this.tokenService.resetToken();
    if (this.userRole === 'ROLE_ADMIN') {
        this.menuMainEmitter.emit(true);
        this.menuFooterEmitter.emit(true);
        this.menuAdminEmitter.emit(false);
    }
    this.autenticado = false;
    this.router.navigate(['/']);
  }

  cadNews(newsEmail: any): Observable<TokenApi> {
    const url = `${environment.pharmaApiUrl}/newslatter/cadastra`;
    return this.http.post<TokenApi>(url, newsEmail).pipe(
      finalize(() => { this.resetSessao(); })
    );
  }

  cadMails(mailsCad: any): Observable<TokenApi> {
    const url = `${environment.pharmaApiUrl}/mails/cadastra`;
    return this.http.post<TokenApi>(url, mailsCad).pipe(
      finalize(() => { this.resetSessao(); })
    );
  }

  usuarioAutenticado() {
    return this.autenticado;
  }

}