import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Usuario } from 'src/app/interfaces/usuario';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  menuMain:   boolean = true;
  menuAdmin:  boolean = false;
  menuCli:    boolean = false;
  menuFarma:  boolean = false;
  menuInd:    boolean = false;
  menuRepre:  boolean = false;

  // userName:   string  = '';
  userAvatar: string  = '';

  public userName: Usuario = <Usuario>{};

  [x: string]: any;
  validatingForm: FormGroup;
  searchText = new Subject();
  results: Observable<any>;
  data: any = [];

  reactiveForm: FormGroup = new FormGroup({
    reactiveRadio: new FormControl(true)
  })

  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }
  
  constructor(private authService: AuthService,
              private router: Router,
              private toastr: ToastService,
              private httpClient: HttpClient,              
              public translate: TranslateService) {

         const nav = navigator.language;
        translate.addLangs(['pt-BR', 'es', 'en']);
        translate.setDefaultLang(nav);
        this.reactiveForm.controls['reactiveRadio'].valueChanges.subscribe((state: any) => {
                if(state === 1){
                  this.translate.use('pt-BR');
                } else if(state === 2){
                  this.translate.use('es');
                } else if(state === 3){
                  this.translate.use('en');
                }
        })                
  }

  ngOnInit(): void {
    this.validatingForm = new FormGroup({
      modalFormDarkEmail:    new FormControl('', Validators.email),
      modalFormDarkPassword: new FormControl('', Validators.required)
    });

    this.innerWidth = window.innerWidth;    

    this.authService.usuarioLogadoEmitter.subscribe(
      puser => this.userName = puser
    );

    this.authService.menuColorEmitter.subscribe(
      mostrar => this.menuColor = mostrar
    );

    this.authService.menuMainEmitter.subscribe(
      mostrar => this.menuMain = mostrar
    );

    this.authService.menuAdminEmitter.subscribe(
      mAdmin => this.menuAdmin = mAdmin
    );

    this.authService.menuClienteEmitter.subscribe(
      mCliente => this.menuCli = mCliente
    );

    this.authService.menuFarmaEmitter.subscribe(
      mFarma => this.menuFarma = mFarma
    );

    this.authService.menuIndEmitter.subscribe(
      mInd => this.menuInd = mInd
    );

    this.authService.menuRepreEmitter.subscribe(
      mRepre => this.menuRepre = mRepre
    );
  }

  ngOnDestroy(){
    this.authService.usuarioLogadoEmitter.unsubscribe();
    this.authService.menuColorEmitter.unsubscribe();
    this.authService.menuMainEmitter.unsubscribe();
    this.authService.menuAdminEmitter.unsubscribe();
    this.authService.menuClienteEmitter.unsubscribe();
    this.authService.menuFarmaEmitter.unsubscribe();
    this.authService.menuIndEmitter.unsubscribe();
    this.authService.menuRepreEmitter.unsubscribe();
  }

  sair() {
    const options = { positionClass: 'md-toast-top-center' };
    this.authService.deslogar().subscribe({
      next: (response) => {
        console.log('Response: ',response)
        this.toastr.warning('Logout realizado com sucesso.', 'ATENÇÃO') 
      },
      error: (err) => console.log(err),
      complete: () => {}
    });
    console.log('Logout!!!');
    this.router.navigate(['/','dashboard']);
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
  
  get modalFormDarkEmail() {
    return this.validatingForm.get('modalFormDarkEmail');
  }
  
  get modalFormDarkPassword() {
    return this.validatingForm.get('modalFormDarkPassword');
  }
  
  filter(value: string): string[] | undefined {
    const filterValue = value.toLowerCase();
    if (this.data && this.data['results']) {
      return this.data['results'].filter((item: any) =>
        item.name.toLowerCase().includes(filterValue)
      );
    }
  } 
  
  onSubmit() {
    // do something here
  }

  scrollME(Status: boolean):void {
    if (Status) {
      this.authService.mostraBtnBottomEmitter.emit(true);
    } else {
      this.authService.mostraBtnBottomEmitter.emit(false);
    }      
  }
    
  voltaHome(): void{
    // this.mostra.menu4MenusEmitter.emit(false);
    // this.mostra.menuMainEmitter.emit(true);
    this.router.navigate(['/']);
  }

  mobile(): void{
    this.authService.menuMainEmitter.emit(false);
    this.authService.menuSharedEmitter.emit(true);
    this.authService.menuColorEmitter.emit(true);
    this.router.navigate(['/mobile']);
  }

  desktop(): void{
    this.authService.menuMainEmitter.emit(false);
    this.authService.menuSharedEmitter.emit(true);
    this.authService.menuColorEmitter.emit(true);
    this.router.navigate(['/desktop']);
  }

}
